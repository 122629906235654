import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 506.000000 528.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,528.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 2640 l0 -2640 1069 0 c703 0 1072 3 1076 10 3 6 19 10 35 10 16 0
32 -4 35 -10 4 -7 490 -10 1426 -10 l1419 0 0 2640 0 2640 -2530 0 -2530 0 0
-2640z m3862 2269 c91 -23 173 -125 198 -246 27 -127 -50 -260 -202 -352 l-37
-22 33 -52 c164 -256 240 -565 215 -869 -42 -508 -362 -954 -828 -1157 -179
-78 -355 -115 -556 -115 -230 0 -422 46 -625 150 -142 74 -224 136 -360 274
-89 90 -130 141 -179 223 -35 59 -67 107 -70 107 -3 -1 -18 -27 -33 -60 -44
-98 -134 -148 -251 -137 -134 12 -257 164 -257 319 0 169 123 351 318 471 l82
50 0 56 c0 164 75 432 162 586 16 28 34 61 41 75 6 14 14 27 17 30 4 3 22 28
40 55 45 66 72 98 145 170 112 111 161 149 285 222 142 83 305 142 464 169
122 20 380 15 496 -11 293 -65 572 -223 755 -430 33 -37 64 -74 68 -81 12 -20
27 -17 76 15 151 100 203 220 156 360 -20 62 -82 133 -140 161 -53 26 -164 29
-219 6 -40 -17 -45 -30 -17 -45 10 -6 24 -24 29 -40 9 -25 7 -35 -12 -63 -44
-67 -136 -37 -136 44 0 34 6 46 36 75 77 72 181 93 306 62z m-3024 -3326 c2
-18 -1 -40 -6 -49 -8 -14 -12 -11 -22 20 l-12 37 -56 -3 -57 -3 0 -205 0 -205
38 -3 c20 -2 37 -7 37 -13 0 -5 -44 -9 -100 -9 -60 0 -100 4 -100 10 0 6 16
10 35 10 l35 0 0 210 0 211 -62 -3 c-56 -3 -63 -5 -68 -27 -7 -33 -30 -40 -30
-9 0 13 3 33 6 46 l7 23 176 -3 176 -3 3 -32z m200 25 c-3 -7 -14 -14 -26 -16
-22 -3 -22 -6 -22 -206 0 -228 -7 -267 -61 -316 -30 -27 -84 -48 -95 -37 -3 2
11 14 30 25 68 40 71 50 74 304 4 223 3 228 -17 228 -21 0 -35 13 -24 24 3 3
37 6 76 6 50 0 68 -3 65 -12z m335 -3 c-1 -5 -14 -11 -28 -13 -19 -3 -25 -8
-21 -18 3 -8 31 -92 62 -187 32 -95 59 -174 61 -176 8 -10 16 15 60 165 60
209 61 214 34 214 -12 0 -21 6 -21 15 0 12 13 15 65 14 62 -2 87 -15 45 -26
-15 -4 -34 -51 -93 -227 -60 -179 -77 -222 -93 -224 -15 -2 -24 10 -40 55 -47
127 -74 202 -105 291 -25 74 -37 96 -56 103 -13 5 -21 14 -18 19 9 14 151 9
148 -5z m631 -1 c3 -9 6 -27 6 -40 0 -31 -27 -32 -32 -1 -3 20 -9 22 -91 27
-49 3 -91 3 -93 1 -7 -8 -4 -180 4 -184 4 -2 31 -1 61 1 42 3 54 8 58 23 3 10
12 19 20 19 11 0 13 -13 11 -57 -3 -57 -23 -85 -30 -41 -3 21 -8 23 -63 23
l-60 0 0 -100 0 -100 92 -3 93 -3 17 36 c9 19 22 34 27 32 11 -4 9 -67 -2 -79
-10 -10 -316 -11 -322 -1 -3 4 5 9 18 11 l22 3 0 210 c0 200 -1 209 -19 209
-21 0 -34 13 -24 24 3 3 73 6 154 6 125 0 148 -2 153 -16z m223 -31 c15 -27
30 -50 34 -53 3 -3 8 -10 10 -15 2 -6 45 -78 94 -160 l90 -150 3 185 c1 101 0
190 -2 197 -3 7 -15 13 -27 13 -12 0 -19 5 -17 13 3 7 28 13 66 15 49 2 62 0
62 -12 0 -8 -10 -16 -22 -18 -27 -4 -26 8 -27 -250 l-1 -188 -27 0 c-29 0 -43
19 -215 299 -36 60 -59 89 -63 80 -3 -8 -4 -91 -3 -184 l3 -170 28 -3 c30 -4
38 -22 10 -23 -105 -4 -159 3 -119 16 9 3 23 7 31 10 13 4 15 33 13 207 -3
202 -3 203 -25 206 -13 2 -23 10 -23 18 0 10 13 14 50 14 48 0 50 -1 77 -47z
m721 0 c4 -50 -9 -56 -25 -13 -11 28 -14 30 -67 30 l-56 0 0 -210 0 -210 34 0
c19 0 38 -4 41 -10 4 -6 -32 -10 -101 -10 -66 0 -104 4 -99 9 6 5 24 11 40 13
l30 3 0 205 0 205 -57 3 c-55 3 -58 2 -73 -28 -19 -36 -34 -32 -28 7 9 56 0
54 185 51 l173 -3 3 -42z m175 32 c-1 -5 -12 -12 -25 -16 -23 -5 -23 -6 -22
-160 0 -178 12 -220 74 -252 46 -24 112 -18 145 13 40 38 49 83 50 244 0 149
0 150 -23 155 -42 10 -23 26 34 29 64 3 92 -11 52 -27 l-26 -10 -1 -143 c-1
-79 -6 -159 -11 -179 -21 -78 -79 -119 -169 -119 -87 0 -143 35 -161 99 -5 20
-10 105 -11 189 -1 152 -1 153 -25 162 -48 18 -26 31 49 28 41 -2 71 -7 70
-13z m627 -3 c81 -40 81 -151 -1 -215 l-31 -24 33 -45 c19 -25 47 -66 62 -93
15 -26 37 -49 47 -52 36 -9 21 -23 -24 -23 l-44 0 -41 78 c-57 108 -71 122
-119 122 -22 0 -42 -4 -44 -10 -1 -5 -2 -46 0 -90 l4 -80 29 0 c16 0 29 -4 29
-10 0 -6 -36 -10 -87 -10 -52 0 -83 4 -78 9 6 5 19 11 30 13 19 3 20 11 20
208 0 205 0 205 -22 208 -23 3 -29 13 -16 25 3 4 54 7 112 7 81 0 114 -4 141
-18z m488 -24 c2 -28 -1 -38 -12 -38 -8 0 -16 10 -18 23 -3 21 -8 22 -95 25
l-93 3 0 -90 c0 -49 3 -92 8 -94 4 -2 31 -1 60 1 45 3 55 8 63 29 15 39 29 16
29 -48 0 -62 -14 -77 -30 -33 -9 23 -14 25 -67 22 l-58 -3 0 -100 0 -100 75
-3 c97 -4 115 1 130 38 7 16 16 30 21 30 12 0 11 -74 0 -86 -13 -12 -331 -6
-331 6 0 6 11 10 25 10 l25 0 0 210 0 210 -25 0 c-16 0 -25 6 -25 15 0 13 22
15 158 13 l157 -3 3 -37z m341 31 c28 -10 31 -15 31 -50 0 -28 -4 -39 -15 -39
-8 0 -15 7 -15 15 0 27 -47 65 -80 65 -53 0 -97 -25 -105 -59 -11 -50 12 -81
105 -138 108 -66 130 -91 130 -148 0 -37 -5 -47 -35 -73 -19 -17 -51 -34 -70
-38 -35 -6 -142 1 -171 13 -18 7 -20 97 -1 91 6 -3 13 -14 15 -27 7 -47 114
-76 171 -46 88 45 52 134 -81 201 -64 32 -92 60 -107 103 -15 46 10 99 59 123
40 20 121 23 169 7z m-1844 -861 c10 -32 21 -58 25 -58 3 0 14 25 24 55 16 52
34 80 21 33 -15 -54 -37 -108 -45 -108 -5 0 -16 21 -26 48 -9 26 -24 52 -32
59 -8 7 -12 19 -8 29 9 24 21 8 41 -58z m261 56 c-3 -9 -7 -43 -8 -77 l-2 -62
-5 70 c-3 39 -5 73 -3 78 6 15 24 6 18 -9z m508 -59 c-1 -42 -4 -79 -8 -82 -3
-3 -6 27 -6 69 0 61 4 88 14 88 1 0 1 -34 0 -75z m202 59 c-3 -9 -7 -43 -8
-77 l-2 -62 -5 70 c-3 39 -5 73 -3 78 6 15 24 6 18 -9z m-2066 -4 c0 -5 -9
-19 -20 -30 -21 -21 -23 -19 -14 18 6 21 34 32 34 12z m165 -64 c0 -41 -3 -72
-7 -69 -15 8 -9 143 6 143 1 0 1 -33 1 -74z m461 -16 c39 0 64 18 64 45 0 13
-8 29 -17 34 -10 6 -13 11 -7 11 28 1 47 -60 23 -76 -7 -4 -8 -19 -5 -38 6
-31 6 -31 -9 -8 -11 14 -26 22 -46 22 -23 0 -29 -4 -30 -22 -1 -22 -1 -22 -8
2 -4 14 -5 48 -2 75 l6 50 3 -47 c3 -44 5 -48 28 -48z m177 80 c-45 -18 -52
-71 -14 -114 15 -18 15 -18 -6 -5 -21 13 -33 37 -33 70 0 21 37 59 58 58 13 0
12 -2 -5 -9z m87 -8 c30 -24 29 -76 -1 -108 -22 -24 -49 -32 -49 -15 0 5 11
12 24 15 22 5 36 30 36 64 0 7 -11 24 -25 37 -28 29 -18 33 15 7z m95 -37 c9
-30 21 -55 25 -55 5 0 14 17 21 38 7 20 14 32 16 26 4 -12 -35 -109 -41 -103
-11 10 -54 149 -47 149 5 0 16 -25 26 -55z m490 30 c-37 -36 -33 -79 10 -103
11 -6 14 -12 8 -12 -23 0 -53 39 -53 70 0 29 29 70 50 70 5 0 -2 -11 -15 -25z
m115 -9 c16 -30 5 -83 -20 -96 -30 -16 -31 -12 -5 15 30 30 31 54 4 89 -13 16
-15 24 -6 20 8 -3 20 -16 27 -28z m177 28 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m406 -62 c3 -34 1 -65 -4 -68 -5 -3 -9 27 -9 67 0 87 7 87
13 1z m116 -4 c-1 -58 -3 -66 -8 -37 -7 34 -3 109 5 109 2 0 3 -33 3 -72z m83
47 c-2 -14 -6 -25 -8 -25 -2 0 -4 11 -4 25 0 14 4 25 9 25 4 0 6 -11 3 -25z
m181 18 c-7 -2 -14 -19 -14 -36 -1 -25 -3 -27 -6 -9 -6 30 3 52 19 51 10 0 10
-2 1 -6z m81 -2 c4 -5 -3 -7 -14 -4 -23 6 -26 13 -6 13 8 0 17 -4 20 -9z
m-2734 -41 c0 -13 -1 -13 -10 0 -5 8 -10 22 -10 30 0 13 1 13 10 0 5 -8 10
-22 10 -30z m170 -34 c-12 -31 -26 -56 -31 -56 -5 0 -9 2 -9 4 0 2 -3 13 -7
23 -6 15 -4 16 9 6 13 -11 18 -5 34 43 10 30 20 51 22 46 1 -5 -7 -35 -18 -66z
m328 37 c-10 -26 -9 -35 5 -57 19 -29 12 -36 -9 -8 -17 21 -17 44 -2 77 16 35
20 26 6 -12z m1270 9 c-15 -38 -3 -81 25 -92 12 -5 16 -9 8 -9 -41 -2 -70 86
-39 117 17 17 18 15 6 -16z m971 -36 c-9 -8 -10 -7 -5 7 3 10 7 24 7 30 1 7 3
4 6 -7 3 -10 -1 -23 -8 -30z m-2620 -6 c0 -10 -3 -8 -9 5 -12 27 -12 43 0 25
5 -8 9 -22 9 -30z m2361 10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m-2445 -68 c-9 -9 -22 10 -29 43 l-7 30 21 -34 c12
-18 19 -36 15 -39z m21 42 c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11
12 3 -3 3 -13 -1 -22z m560 -30 c-3 -8 -11 -14 -18 -13 -10 0 -10 2 0 6 6 2
13 17 13 31 1 18 3 21 6 9 3 -10 2 -25 -1 -33z m1260 0 c-3 -8 -11 -14 -18
-13 -10 0 -10 2 0 6 6 2 13 17 13 31 1 18 3 21 6 9 3 -10 2 -25 -1 -33z m773
34 c0 -7 -4 -22 -9 -33 -7 -17 -9 -15 -9 13 -1 17 4 32 9 32 6 0 10 -6 9 -12z
m-182 -20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m261 1 c19
-30 8 -34 -12 -4 -9 14 -13 25 -9 25 5 0 14 -9 21 -21z m-248 -29 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M3560 4800 c-11 -11 -20 -26 -20 -34 0 -19 27 -46 46 -46 50 0 71 51
36 83 -24 22 -38 21 -62 -3z"/>
<path d="M2610 4804 c-495 -50 -877 -296 -1097 -704 -50 -93 -119 -280 -128
-350 -19 -136 -25 -214 -18 -221 5 -5 21 -2 37 6 16 8 34 15 42 15 7 0 15 15
18 33 3 17 13 74 21 125 19 116 43 190 105 317 65 134 113 204 210 307 179
192 398 314 659 369 104 21 339 27 413 9 174 -41 251 -66 342 -109 141 -66
242 -137 355 -249 96 -96 107 -101 150 -73 20 13 3 36 -101 141 -190 191 -436
321 -691 364 -88 15 -260 26 -317 20z"/>
<path d="M2505 4674 c-112 -17 -254 -64 -365 -119 -354 -178 -602 -527 -649
-909 -5 -47 -4 -66 4 -66 6 0 58 19 116 42 57 23 185 69 284 102 l180 61 3
116 3 117 207 7 c114 4 317 6 452 4 l245 -3 130 41 c311 98 505 168 505 183 0
14 -160 169 -209 203 -25 17 -48 34 -51 38 -12 18 -222 117 -300 142 -170 54
-373 69 -555 41z"/>
<path d="M3712 4244 l-33 -14 40 -59 c80 -118 143 -269 188 -451 14 -59 18
-112 17 -245 0 -152 -3 -181 -28 -274 -67 -257 -175 -442 -357 -615 -379 -361
-943 -446 -1400 -210 -320 165 -551 456 -639 804 -22 89 -44 266 -38 315 2 19
1 35 -3 35 -3 0 -28 -9 -54 -21 l-48 -21 7 -97 c37 -536 395 -994 908 -1162
467 -153 967 -43 1319 288 141 134 268 320 328 483 134 364 119 717 -44 1057
-53 111 -109 204 -123 202 -4 0 -22 -7 -40 -15z"/>
<path d="M3530 4175 c-52 -19 -160 -55 -240 -80 -189 -60 -186 -59 -105 -65
l70 -5 3 -142 c2 -121 0 -143 -12 -143 -9 0 -19 16 -26 43 -22 84 -31 105 -65
144 -38 45 -89 63 -172 63 -48 0 -209 -42 -226 -59 -4 -4 -7 -233 -5 -508 3
-455 5 -501 20 -526 25 -37 45 -45 116 -48 67 -2 93 -12 77 -28 -11 -11 -591
-19 -601 -8 -4 3 -4 13 -1 22 5 11 22 15 67 15 67 0 113 18 132 53 15 26 26
964 11 977 -9 8 -466 -119 -471 -132 -2 -4 -14 -8 -27 -8 -31 0 -205 -55 -395
-125 -85 -32 -166 -60 -179 -63 l-24 -5 6 -126 c15 -306 128 -560 347 -782
234 -236 536 -362 870 -362 324 1 621 127 851 363 169 172 280 385 326 619 33
172 17 424 -38 591 -47 146 -166 361 -199 360 -8 -1 -58 -16 -110 -35z"/>
<path d="M2210 3968 c-33 -23 -109 -152 -96 -165 3 -4 57 8 118 25 62 17 166
45 231 63 l118 32 -3 31 -3 31 -166 3 c-158 2 -167 1 -199 -20z"/>
<path d="M2760 3980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1356 3743 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M1206 3405 c-194 -131 -288 -291 -273 -464 12 -144 112 -257 236
-268 73 -7 122 9 173 55 45 41 60 68 77 136 12 49 4 81 -50 211 -18 45 -41
156 -51 255 -12 118 -14 130 -25 130 -4 -1 -44 -25 -87 -55z"/>
<path d="M3594 1587 c-5 -8 -8 -59 -6 -111 l3 -96 45 0 c88 0 131 44 121 126
-7 62 -30 84 -99 91 -44 4 -57 2 -64 -10z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
